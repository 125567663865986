export const PAGE_POSITION = 'PAGE_POSITION'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const AUTH_SYSTEM_ERROR = 'LOGIN_SYSTEM_ERROR';
export const AUTH_LOADING = 'AUTH_LOADING';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const ADD_KELAS_SUCCESS = 'ADD_KELAS_SUCCESS';
export const ADD_KELAS_FAILED = 'ADD_KELAS_FAILED';

// Kelas data
export const KELAS_INIT = 'KELAS_INIT'

//paket Data
export const PAKET_INIT = 'PAKET_INIT'
export const SELECTED_PAKET = 'SELECTED_PAKET'

//Transaction
export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS'
export const TRANSACTION_FAIL = 'TRANSACTION_FAIL'
export const TRANSACTION_ADD =  'TRANSACTION_ADD'