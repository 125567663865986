import React,{ Component } from 'react'

class HomeAdmin extends Component{
    render(){
        return(
            <div className='homeAdmin'>
                <div className='container mt-4 mb-4'>
                    <div className='menuAdmin'>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeAdmin;