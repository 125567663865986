import React,{Component} from 'react'

class Success extends Component{
    render(){
        return(
            <div>
                <center>
                    <h1>Success</h1>
                </center>
            </div>
        )
    }
}

export default Success;