import React,{Component} from 'react'

class notFound extends Component{
    render(){
        return(
            <center>
                <h1>Page not found</h1>
            </center>
        )
    }
}

export default notFound;