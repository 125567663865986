import React,{Component} from 'react'

class Unfinish extends Component{
    render(){
        return(
            <div>
                <center>
                    <h1>Unfinish</h1>
                </center>
            </div>
        )
    }
}

export default Unfinish;